import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { getAPI, postAPI, postFile, postMultipleFile, putAPI } from '../utils/api';
import Select from 'react-select';
import { storageUrl } from '../utils/BaseUrl';
import ReactQuill from 'react-quill';

export default class CreateType extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            order: '',
            optionss: [],
            selectedOptions: [],
            primaryOptions: [],
            categories: [],
            thumbNailImage: '',
            images: [],
            slug: '',
            desc: '',
            header: "",
            footer: "",
            seoTitle:"",
            seoDesc:"",
            seoKeywords:"",
            seoMeta:"",
            files_body_content: [],
            multiple_file_body_content: [],
            files_Movement_content: [],
            files_Performace_content: [],
            files_Movement: null,
            files_Performace: null,
            err_image_msg: '',
            err_image_msgg: '',
            err_image_msggg: '',
            imageUploading: false,
            errors: {
                name: '',
                order: '',
                desc: '',
                slug: '',
                alreadyexist: '',
                header: "",
                footer: ""
            },
            isRequired: false,
            isAlready: false,
            newOptions: [ { label: "--Choose No Option--", value: "" } ],
            ogFields: {
                ogTitle: "",
                ogDescription: "",
                ogImage: ""
            }

        }
    }

    componentDidMount() {
        if (this.props.location.state.status === 1) {
            const data = this.props.location.state.data;
            const selectedCategories = data.categories;
            const optionArray = selectedCategories.map(d => {
                if(!d.isPrimary){
                    let a = {};
                    a['label'] = d.name;
                    a['value'] = d.uid;
                    return a;
                }
            })
            const primaryArray = selectedCategories.map(d => {
                if(d.isPrimary){
                    let b = {}
                    b['label'] = d.name;
                    b['value'] = d.uid;
                    return b
                }
            })
            this.setState({
                name: data.name,
                images: data.images,
                thumbNailImage: data.thumbNailImage,
                order: data.order,
                selectedOptions: optionArray,
                primaryOptions: primaryArray,
                slug: data.slug,
                desc: data.desc,
                header: data.header,
                footer: data.footer,
                seoMeta:data.seoFields?.seoMeta,
                seoTitle:data.seoFields?.seoTitle,
                seoDesc:data.seoFields?.seoDesc,
                seoKeywords:data.seoFields?.seoKeywords,
                ogFields:data?.seoFields?.ogFields,

            })
            this.getCategories();
        }
        else {
            this.getCategories();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.setState({ categories: [] });
            this.getCategories();
        }
    }

    getCategories() {
        const url = 'category/category/' + this.props.langId;
        getAPI(url)
            .then(res => {
                const { data, status } = res.data;
                // console.log(data)
                if (status === 1 && data.length >= 0) {
                    this.setState({ categories: data })
                    const optionArray = data.map(d => {
                        let a = {};
                        a['label'] = d.name;
                        a['value'] = d.uid;
                        return a;
                    })
                    this.setState({ optionss: optionArray });

                } else {
                    this.setState({ categories: [] })
                }

            }).catch(err => console.log('lang error: ', err.response))
    }

    routeChange = () => {
        let path = `/types`;
        this.props.history.push(path);
    }

    handleChangeEditor = (e, id) => {
        this.setState({ [id]: e, errors: { ...this.state.errors, [id]: "" } })
    }

    handleChange = (e) => {
        let errors = this.state.errors;
        let { name, value } = e.target;
        switch (name) {
            case 'name':
                errors.name =
                    value.length <= 0
                        ? 'Field is Required!'
                        : '';
                this.setState({ slug: e.target.value.replace(/\s+/g, '-').toLowerCase() });
                break;
            case 'slug':
                errors.slug =
                    value.length <= 0
                        ? 'Field is Required!'
                        : '';
                break;
            case 'order':
                errors.order =
                    value.length <= 0
                        ? 'Field is Required!'
                        : '';
                break;
            case 'desc':
                errors.desc =
                    value.length <= 0
                        ? 'Field is Required!'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [e.target.name]: e.target.value })
    }

    handleSelect = (selectedOptions, unique) => {
        if (unique === "primary") {
            let optionss = [...this.state.newOptions, ...this.state.optionss]
            this.setState({ primaryOptions: selectedOptions }, () => {
                let findIndex = optionss.findIndex(ele => ele.label === selectedOptions.label)
                if (findIndex > -1) {
                    optionss.splice(findIndex, 1)
                    this.setState({ newOptions: optionss })
                }
            });
        } else {
            this.setState({ selectedOptions: selectedOptions })
        }
    }

    handleClick = (e) => {
        const seoFields={
            seoTitle:this.state.seoTitle,
            seoMeta:this.state.seoMeta,
            seoDesc:this.state.seoDesc,
            seoKeywords:this.state.seoKeywords,
            ogFields:this.state.ogFields
        }
        const { status } = this.props.location.state;
        const languageUid = this.props.langId;
        const name = this.state.name;
        const order = this.state.order;
        const thumbNailImage = this.state.thumbNailImage;
        const images = this.state.images;
        const slug = this.state.slug;
        const desc = this.state.desc;
        const primaryCategory = this.state.primaryOptions.value
        const categ = this.state.selectedOptions.value
        const header = this.state.header;
        const footer = this.state.footer;
        const errors = this.state.errors;
        if (name.length === 0 || order.length === 0 || desc.length === 0 || slug.length === 0 || header?.length === 0 || footer?.length === 0) {
            if (name.length === 0 && order.length === 0 && desc.length === 0 && slug.length === 0 && header?.length === 0 && footer?.length === 0) {
                errors.name = 'Field is Required!';
                errors.order = 'Field is Required!';
                errors.slug = 'Field is Required!';
                errors.desc = 'Field is Required!';
                errors.header = 'Field is Required!';
                errors.footer = 'Field is Required!';
                this.setState({ errors, isRequired: true })
            }
            else {
                if (name.length === 0) {
                    errors.name = 'Field is Required!';
                    this.setState({ errors, isRequired: true })
                }
                if (desc.length === 0) {
                    errors.desc = 'Field is Required!';
                    this.setState({ errors, isRequired: true })
                }
                if (slug.length === 0) {
                    errors.slug = 'Field is Required!';
                    this.setState({ errors, isRequired: true })
                }
                if (order.length === 0) {
                    errors.order = 'Field is Required!';
                    this.setState({ errors, isRequired: true })
                }
                if (header?.length === 0) {
                    errors.header = 'Field is Required!';
                    this.setState({ errors, isRequired: true })
                }
                if (footer?.length === 0) {
                    errors.footer = 'Field is Required!';
                    this.setState({ errors, isRequired: true })
                }
            }
        }
        else if (status === 0) {
            
            const data = {
                name: name,
                order: order,
                languageUid: languageUid,
                images: images,
                thumbNailImage: thumbNailImage,
                categories: categ?.length>0?[categ]:[],
                slug: slug,
                desc: desc,
                primaryCategory: primaryCategory,
                header: header,
                footer: footer,
                seoFields:seoFields
            }

            postAPI('type/type', data).then(res => {
                if (res.data.status === 1) {
                    this.props.history.push({
                        pathname: '/types',
                        //prevState: res.data.data 
                    })
                } else if (res.data.status === 2) {
                    errors.alreadyexist = 'Slug already exists';
                    this.setState({ errors, isAlready: true })
                    this.setErrorTimeout();
                } else {
                    alert('not created');
                }
            }).catch(err => {
                console.log('Error', err);
            })
            // console.log(data);
        }
        else {
            const data = {
                name: name,
                order: order,
                images: images,
                thumbNailImage: thumbNailImage,
                categories: categ?[categ]:[],
                slug: slug,
                desc: desc,
                primaryCategory: primaryCategory,
                header: header,
                footer: footer,
                seoFields:seoFields
            }
            const url = 'type/type/' + this.props.location.state.data.uid;
            putAPI(url, data).then(res => {
                if (res.data.status === 1) {
                    this.props.history.push({
                        pathname: '/types',
                        //prevState: res.data.data 
                    })
                }
                else if (res.data.status === 2) {
                    errors.alreadyexist = 'Slug already exists';
                    this.setState({ errors, isAlready: true })
                    this.setErrorTimeout();
                } else {
                    alert('type not created')
                }
            }).catch(err => {
                console.log(err);
            })
            // console.log(data);
        }
    }

    onDropBody = (acceptedFiles, string) => {
        console.log(acceptedFiles, string)
        if (string === 'multiple') {
            console.log('accepted Files', acceptedFiles);
            // let multipleFiles=[];
            let content = [];
            let images = [];
            this.setState({ imageUploading: true });
            postMultipleFile('upload/upload/types', acceptedFiles).then(res => {
                console.log(res.data);
                const { status, data } = res.data;
                if (status === 1) {
                    for (let i = 0; i < data.length; i++) {
                        content[i] = data[i].originalname;
                        images[i] = data[i].s3key;
                    }
                    this.setState({ imageUploading: false, images: images, multiple_file_body_content: content })
                }
                else {
                    alert('Image not Uploaded')
                }
            }).catch(err => {
                console.log('Error', err);
            })
        } else if (string === 'single') {
            if (acceptedFiles[0].type !== 'image/jpeg' && acceptedFiles[0].type !== 'image/png') {
                alert('Only JPEG and PNG file accepted.')
            }
            this.setState({ imageUploading: true })
            postFile('upload/upload/types', acceptedFiles[0])
                .then(res => {
                    if (res.data.status === 1) {
                        console.log('Hello', res.data.data[0].s3key);
                        this.setState({
                            imageUploading: false,
                            thumbNailImage: res.data.data[0].s3key,
                            files_body_content: acceptedFiles[0]
                        });
                    }
                    else {
                        alert('Image not Uploaded')
                    }
                }).catch(err => console.log(err))
        } else {
            alert('Only JPEG and PNG file accepted.')
        }

    }

    setErrorTimeout = () => {
        setTimeout(() => this.setState({ isAlready: false }), 5000);

    };

    handleOgDataChange = e => {
        console.log(e.target.getAttribute('name'), e.target.value)
        this.setState({
            ogFields:{
                ...this.state.ogFields,
                [e.target.getAttribute('name')] : e.target.value
                
            }
        })
    }

    render() {
        const { status } = this.props.location.state;
        const { errors, isRequired, isAlready, ogFields } = this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">{status === 1 ? 'Edit Type' : 'Create New Type'}</h1>
                                    </div>
                                    <div className="col-auto">
                                        {/* <a href="#!" className="btn btn-primary lift">Create Type </a> */}
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2">Sort Order:</span>
                                            <input type="text" className="form-control width-auto" name="order" value={this.state.order} onChange={e => { this.handleChange(e) }} />
                                        </div>
                                        {isRequired && errors.order.length > 0 ?
                                            <span className="error-field-error">{errors.order}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        {isAlready && errors.alreadyexist.length > 0 ? <div className="alert alert-danger text-center" role="alert">
                            <div className="text-center">{errors.alreadyexist}</div>
                        </div> : ''}
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Type Name</label>
                                    <input type="text" className="form-control" name="name" value={this.state.name} onChange={e => { this.handleChange(e) }} />
                                    {isRequired && errors.name.length > 0 ?
                                        <span className="error-field-error">{errors.name}</span> : ''}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Primary Categories</label>
                                    <Select
                                        options={this.state.optionss}
                                        onChange={(e) => this.handleSelect(e, 'primary')}
                                        value={this.state.primaryOptions}

                                    />
                                </div>
                                <div className="form-group">
                                    <label> Sub Categories</label>
                                    <Select
                                        options={this.state.newOptions}
                                        onChange={(e) => this.handleSelect(e, 'subCateg')}
                                        value={this.state.selectedOptions}

                                    />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Description</label>
                                    {/* <textarea className="form-control" name="desc" value={this.state.desc} onChange={e => { this.handleChange(e) }}></textarea> */}
                                    <ReactQuill
                                        theme="snow"
                                        modules={this.modules}
                                        formats={this.formats}
                                        onChange={(e) => this.handleChangeEditor(e, 'desc')}
                                        value={this.state.desc}
                                    />
                                    {isRequired && errors.desc.length > 0 ?
                                        <span className="error-field-error">{errors.desc}</span> : ''}
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Slug</label>
                                    <input type="text" className="form-control" name="slug" value={this.state.slug} onChange={e => { this.handleChange(e) }} />
                                    {isRequired && errors.slug.length > 0 ?
                                        <span className="error-field-error">{errors.slug}</span> : ''}
                                </div>
                                <hr className="my-5" />
                            </div>

                            <div className="col-12">

                                <div className="form-group">
                                    <label>Thumbnail Image</label>
                                    <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple">
                                        <div className="dz-default dz-message">
                                            <Dropzone onDrop={e => this.onDropBody(e, 'single')} accept={'image/jpeg, image/png'}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} className="back-inp c-pointer" />
                                                        <span className="dz-button center-box">Drop file here to upload</span>
                                                        {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    {this.state.thumbNailImage ?
                                        <div className="box-im d-flex align-items-center mt-4">
                                            <div className="img-box-s mr-2">
                                                <img className="drop-image" src={`${storageUrl+this.state.thumbNailImage}`} alt="..." />
                                            </div>

                                            <div className="show-img-info">
                                                <h4 className="mb-0  f-size">{this.state.files_body_content.name ? this.state.files_body_content.name : this.state.thumbNailImage.split('/').pop()}</h4>
                                            </div>

                                        </div>
                                        : null}
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Images</label>
                                    <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple">
                                        <div className="dz-default dz-message">
                                            <Dropzone onDrop={(e) => { this.onDropBody(e, 'multiple') }} multiple accept={'image/jpeg, image/png'}> 
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} className="back-inp c-pointer" />
                                                        <span className="dz-button center-box">Drop file here to upload</span>
                                                        {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    {this.state.images.length > 0 ? this.state.images.map((image, index) => (
                                        <div className="box-im d-flex align-items-center mt-4" key={index}>
                                            <div className="img-box-s mr-2">
                                                <img className="drop-image" src={this.state.images[index]} alt="..." />
                                            </div>

                                            <div className="show-img-info">
                                                <h4 className="mb-0  f-size">{this.state.multiple_file_body_content[index] ? this.state.multiple_file_body_content[index] : this.state.images[index].split('/').pop()}</h4>
                                            </div>

                                        </div>
                                    )) : null}
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Header</label>
                                    <ReactQuill
                                        theme="snow"
                                        modules={this.modules}
                                        formats={this.formats}
                                        onChange={(e) => this.handleChangeEditor(e, 'header')}
                                        value={this.state.header}
                                    />
                                    {isRequired && errors.header.length > 0 ?
                                        <span className="error-field-error">{errors.header}</span> : ''}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Footer</label>
                                    <ReactQuill
                                        theme="snow"
                                        modules={this.modules}
                                        formats={this.formats}
                                        onChange={(e) => this.handleChangeEditor(e, 'footer')}
                                        value={this.state.footer}
                                    />
                                    {isRequired && errors.footer.length > 0 ?
                                        <span className="error-field-error">{errors.footer}</span> : ''}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Title</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="Name..."
                                 
                                 name="seoTitle"
                                 value={this.state.seoTitle}
                                 onChange={e => this.handleChange(e)}
                                 />
                                
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Meta </label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo meta..."
                                 
                                 name="seoMeta"
                                 value={this.state.seoMeta}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Description</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo description..."
                                 
                                 name="seoDesc"
                                 value={this.state.seoDesc}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Keywords</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo keywords..."
                                 required="required"
                                 name="seoKeywords"
                                 
                                 value={this.state.seoKeywords}
                                 onChange={e => this.handleChange(e)}
                                 />
                                </div>
                                <hr className="my-5"/>
                            </div>

                            {/* OG tags fields */}
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Title</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Title..."
                                        required="required"
                                        name="ogTitle"
                                        value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Description</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Description..."
                                        required="required"
                                        name="ogDescription"
                                        value={ogFields?.ogDescription ? ogFields?.ogDescription : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Image</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Image..."
                                        required="required"
                                        name="ogImage"
                                        value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                                <hr className="my-5"/>
                            </div>

                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={this.routeChange} disabled={this.state.imageUploading}>Cancel</button>
                                    <button className="btn btn-primary " onClick={this.handleClick} disabled={this.state.imageUploading}>{status === 1 ? 'Save' : 'Create Type'}</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}

