import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getAPI, putAPI, deleteAPI } from '../../utils/api';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';


export default class Blog extends Component {
    state = {
        blogsData: []
    }

    componentDidMount() {
        this.getBlogs()
    }

    getBlogs = () => {
        getAPI(`blog/AllBlogs/${this.props.langId}`)
            .then(res => {
                let { data, status } = res.data
                // console.log(data);
                if (status === 1) {
                    this.setState({
                        blogsData: data.reverse()
                    })
                } else {
                    this.setState({ blogsData: [] })
                }
            })
            .catch(err => console.log(err))
    }

    publishBlog = (blogUid, status) => {
        if (blogUid !== "" && status !== null) {
            putAPI(`blog/publish/${blogUid}?isPublished=${!status}`)
                .then(res => {
                    let { message, status } = res.data
                    if (status === 1) {
                        this.getBlogs()
                    }
                })
                .catch(error => console.log(error))
        }
    }

    deletePost = (blogUid) => {
        if (blogUid !== "") {
            deleteAPI(`blog/deleteBlog/${blogUid}`)
                .then(res => {
                    const { message, status } = res.data
                    if (status === 1) {
                        this.getBlogs()
                    }
                })
                .catch(error => console.log(error))
        }
    }

    render() {
        let { blogsData } = this.state
        return (
            <>
                <div className="main-section mt-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex">
                                    <h1 className="header-title">Blog</h1>
                                    <Link to="/createblog" className="ml-auto">
                                        <button
                                            className="btn btn-primary"
                                        >
                                            Create Blog
                                </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-4 mb-5" />
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title"> Blog List </h4>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">Tittle</th> */}
                                                    <th >TITLE</th>
                                                    <th >Description</th>
                                                    <th>Category</th>
                                                    <th scope="col">Published</th>
                                                    <th scope="col">UPDATED</th>
                                                    <th colSpan="2">Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    blogsData.length > 0 && blogsData.map((ele, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{ele.title}</td>
                                                                <td>{ReactHtmlParser(ele.desc)}</td>
                                                                <td>{ele.blogCategory?.name}</td>
                                                                <td>{moment(ele.publishedOn).format("DD/MM/YYYY")}</td>
                                                                <td>{moment(ele.updatedAt).format("DD/MM/YYYY")}</td>
                                                                <td>
                                                                    {
                                                                        ele.isPublished
                                                                            ?
                                                                            <span className="badge badge-soft-success">Published</span>
                                                                            :
                                                                            <span className="badge badge-soft-danger">Unpublished</span>
                                                                    }
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="dropdown">
                                                                        <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                            <i className="fe fe-more-vertical"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <Link to={{ pathname: "/createblog", state: { blog: ele } }} className="dropdown-item">
                                                                                Edit
                                                                            </Link>
                                                                            <Link className="dropdown-item" onClick={() => this.publishBlog(ele.uid, ele.isPublished)}>
                                                                                {ele.isPublished ? 'Unpublish' : 'Publish'}
                                                                            </Link>
                                                                            <Link className="dropdown-item" onClick={() => this.deletePost(ele.uid)}>
                                                                                Delete
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <nav aria-label="Page navigation example">
                                    <ul className="pagination pagination-lg justify-content-center">
                                        <li className="page-item"><a class="page-link" href="#!">Previous</a></li>
                                        <li className="page-item active"><a class="page-link" href="#!">1</a></li>
                                        <li className="page-item"><a class="page-link" href="#!">2</a></li>
                                        <li className="page-item"><a class="page-link" href="#!">Next</a></li>
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                    </div>



                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        )
    }
}