import React, { Component } from 'react';
import Select from 'react-select';
import ReactQuill from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css';
import { getAPI, postAPI, postFile, postMultipleFile, putAPI } from '../utils/api';
import UploadComponent from '../Common/UploadComponent';
import { storageUrl } from '../utils/BaseUrl';


export default class CreateProduct extends Component {
    state = {
        order: '1',
        brandLoading: true,
        categoryLoading: true,
        typeAndMaterialLoading: false,
        selectedBrand: null, brandList: [{ label: '--Choose No Option--', value: null, uid: null }],
        selectedCategory: null, categories: [{ label: '--Choose No Option--', value: null, uid: null }],
        selectedMaterial: null, materialList: [{ label: '--Choose No Option--', value: null, uid: null }],
        selectedType: null, typeList: [{ label: '--Choose No Option--', value: null, uid: null }],
        tag: '', desc: '', slug: '', name: '',
        thumbnail: '', thumbnailUploading: false,
        images: [], imagesUploading: false,
        highlights: '', keywords: '', packaging: '',
        seoMetaBulletin: '', seoMetaFragment: '', seoMetaKeyword: '', seoMetaSummary: '', seoMetadesc: '', seoTitle: '',
        videoEmbedCode: '',
        submitting: false,
        isAlready: false,
        errors: {
            alreadyexist: ''
        },
        islink:false,
        link:'',
        ogFields: {
            ogTitle: "",
            ogDescription: "",
            ogImage: ""
        }
    };

    handleLinkInput = () => {
        this.setState({ islink: !this.state.islink,link:''});
    }


    async componentDidMount() {
        const { langId } = this.props;
        await this.getBrand(langId);
        await this.getCategories(langId);
        await this.getproductForEdit()
    }

    getproductForEdit = async () => {
        if (this.props?.location?.state?.productDetail) {
            const { productDetail } = this.props?.location?.state
            console.log("productDetail   ", productDetail);
            await this.setState({
                name: productDetail.name,
                code: productDetail.code,
                desc: productDetail.desc,
                keywords: productDetail.keywords,
                seoTitle: productDetail.seoTitle,
                seoMetaKeyword: productDetail.seoMetaKeyword,
                seoMetadesc: productDetail.seoMetadesc,
                seoMetaFragment: productDetail.seoMetaFragment,
                seoMetaSummary: productDetail.seoMetaSummary,
                seoMetaBulletin: productDetail.seoMetaBulletin,
                packaging: productDetail.packaging,
                highlights: productDetail.highlights,
                videoEmbedCode: productDetail.videoEmbedCode,
                images: productDetail.images,
                order: productDetail.order,
                tag: productDetail.tagline,
                slug: productDetail.slug,
                thumbnail: productDetail.thumbnailImage,
                ogFields:productDetail?.ogFields,
            })
        }
    }

    getBrand = (langId) => {
        getAPI(`brand/brand/${langId}`)
            .then(res => {
                const brandList = res.data.data.map(v => {
                    return { ...v, value: v.uid, label: v.name }
                })
                this.setState({ brandList: [...this.state.brandList, ...brandList], brandLoading: false }, () => {
                    if (this.props.location.state?.productDetail) {
                        const { productDetail } = this.props?.location?.state
                        if (productDetail) {
                            let brandObj = this.state.brandList.filter(ele => ele.value === productDetail.brandUid)
                            if (brandObj.length > 0) {
                                this.setState({
                                    selectedBrand: brandObj[0]
                                })
                            }
                        }
                    }
                });
            })
            .catch(err => console.log('Brand list err: ', err))
    }

    getCategories = langId => {
        getAPI(`category/category/${langId}`)
            .then(res => {
                const categories = res.data.data.map(v => {
                    return { ...v, value: v.uid, label: v.name }
                })
                this.setState({ categories: [...this.state.categories, ...categories], categoryLoading: false }, () => {
                    if (this.props.location.state?.productDetail) {
                        const { productDetail } = this.props?.location?.state
                        if (productDetail) {
                            let categoryObj = this.state.categories.filter(ele => ele.value === productDetail.categoryUid)
                            if (categoryObj.length > 0) {
                                this.setState({
                                    selectedCategory: categoryObj[0]
                                }, () => {
                                    this.getMaterialsAndTypes(this.state.selectedCategory)
                                })
                            }
                        }
                    }
                });
            })
            .catch(err => console.log('Category list err: ', err))
    }

    getMaterialsAndTypes = obj => {
        getAPI(`category/catgeoryTypesandMaterials/${obj.uid}`)
            .then(res => {
                console.log(res)
                const { materials, types } = res.data.data;
                const materialList = materials.map(v => {
                    return { ...v, value: v.uid, label: v.name }
                })
                const typeList = types.map(v => {
                    return { ...v, value: v.uid, label: v.name }
                })
                this.setState({ materialList: [...this.state.materialList, ...materialList], typeList: [...this.state.typeList, ...typeList], typeAndMaterialLoading: false }, async () => {
                    if (this.props.location.state?.productDetail) {
                        const { productDetail } = this.props?.location?.state
                        if (productDetail) {
                            let materialObj = this.state.materialList.filter(ele => ele.value === productDetail.materialUid)
                            if (materialObj.length > 0) {
                                await this.setState({
                                    selectedMaterial: materialObj[0]
                                })
                            }
                            let typeObj = this.state.typeList.filter(ele => ele.value === productDetail.productTypeUid)
                            if (typeObj.length > 0) {
                                await this.setState({
                                    selectedType: typeObj[0]
                                })
                            }
                        }
                    }
                });
            })
            .catch(err => console.log('Get materials and type list err: ', err))
    }

    handleChange = (obj, state) => {
        if (state === 'selectedCategory') {
            this.setState({ [state]: obj, typeAndMaterialLoading: true });
            if (obj.uid !== "") {
                this.getMaterialsAndTypes(obj)
            }
        } else {
            this.setState({ [state]: obj })
        }
    }

    handleInputChange = e => {
        if (typeof (e) === 'string') {
            this.setState({ desc: e })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    removeImage=(e,value)=>{
        let imagesArray=this.state.images;
        let newArray=[];
        for(let i=0;i<imagesArray.length;i++){
            if(imagesArray[i].value!==value){
                imagesArray[i].order=newArray.length;
                newArray.push(imagesArray[i]);
            }
        }
        console.log(newArray);
        this.setState({images:newArray});
    }
    handleUploadImageAndLink=(acceptedFiles,state)=>{
        const imagesArray=this.state.images;
        if(state==='image'){
            postMultipleFile('upload/upload/productImages',acceptedFiles).then(res=>{
                const {status,data}=res.data;
                if(status===1){
                    for (let i = 0; i < data.length; i++) {
                        let obj={
                            type:0,
                            value:data[i].s3key,
                            order:imagesArray.length
                        };
                        imagesArray.push(obj);
                    }
                    // console.log(imagesArray);
                    this.setState({images:imagesArray})
                }else{
                    alert('something went wrong');
                }
                
            }).catch(err=>{
                console.log(err);
            })
        }
        else{
            let link = this.state.link;
            // var video_id = link.split('v=')[1];
            // var ampersandPosition = video_id.indexOf('&');
            // if(ampersandPosition != -1) {
            // video_id = video_id.substring(0, ampersandPosition);
            // }
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = link.match(regExp);
            let video_id='';
            if(match&&match[7].length==11){
                video_id=match[7];
            }
            let obj={
                type:1,
                order:imagesArray.length,
                value:link,
                video_id:video_id
            }
            //console.log(video_id);
            imagesArray.push(obj);
            // console.log(imagesArray);
            this.setState({link:'',images:imagesArray,islink:false});
            
        }
    }

    onDrop = (acceptedFiles, state) => {
        console.log(acceptedFiles, state)
        const self = this;
        const notImageFile = acceptedFiles.some(file => (file.type !== 'image/jpeg' && file.type !== 'image/png'))

        if (state === 'thumbnail' && acceptedFiles.length > 1) {
            alert(`Only one file is allowed in thumbnail. You tried ${acceptedFiles.length} files.`)
        } else if (!notImageFile) {
            state === 'thumbnail'
                ? self.setState({ thumbnailUploading: true })
                : self.setState({ imagesUploading: true });

            const uploads = acceptedFiles.map(file => {
                return new Promise((resolve, reject) => {
                    postFile('upload/upload/productImages', file)
                        .then(res => {
                            console.log(res.data.data[0].s3key);
                            resolve(res.data.data[0].s3key)
                        }).catch(err => reject({ err, file }))
                })
            })

            Promise.all(uploads).then(result => {
                console.log(result);
                if (state === 'thumbnail') {
                    self.setState({ thumbnailUploading: false, thumbnail: result[0] });
                } else {
                    self.setState({ imagesUploading: false, images: result });
                }
            })
                .catch(err => {
                    console.log('Promise error while uploading files: ', err)
                })

        } else {
            alert('Only JPEG and PNG file accepted.')
        }
    }

    handleSave = () => {
        const { name, order, tag, slug, desc, keywords, seoTitle, seoMetaKeyword, seoMetadesc, seoMetaFragment, seoMetaSummary,
            seoMetaBulletin, thumbnail, packaging, highlights, videoEmbedCode, images, selectedCategory, selectedMaterial,
            selectedBrand, selectedType, ogFields } = this.state;
        this.setState({ submitting: true })
        if (this.props.location.state?.productDetail) {
            let { productDetail } = this.props.location.state
            if (productDetail.uid) {
                const data = {
                    name: name,
                    code: "",
                    desc, keywords, seoTitle, seoMetaKeyword, seoMetadesc, seoMetaFragment, seoMetaSummary, seoMetaBulletin,
                    packaging, highlights, videoEmbedCode, images, order,
                    tagline: tag,
                    slug: slug,
                    thumbnailImage: thumbnail,
                    categoryUid: selectedCategory ? selectedCategory.uid : null,
                    materialUid: selectedMaterial ? selectedMaterial.uid : null,
                    brandUid: selectedBrand ? selectedBrand.uid : null,
                    productTypeUid: selectedType ? selectedType.uid : null,
                    ogFields: ogFields ? ogFields : null
                }

                putAPI(`product/product/${productDetail.uid}`, data).then(res => {
                    if (res.data.status === 1) {
                        console.log(res);
                        this.props.history.push('/products');
                    }
                    else if (res.data.status === 2) {
                        alert('Slug Error');
                    } else {
                        alert('product not created')
                    }
                }).catch(err => { console.log('Submit product error: ', err); this.setState({ submitting: false }) })
            }
        } else {
            const data = {
                name: name,
                code: "",
                desc, keywords, seoTitle, seoMetaKeyword, seoMetadesc, seoMetaFragment, seoMetaSummary, seoMetaBulletin,
                packaging, highlights, videoEmbedCode, images, order,
                tagline: tag,
                slug: slug,
                thumbnailImage: thumbnail,
                categoryUid: selectedCategory ? selectedCategory.uid : null,
                materialUid: selectedMaterial ? selectedMaterial.uid : null,
                brandUid: selectedBrand ? selectedBrand.uid : null,
                productTypeUid: selectedType ? selectedType.uid : null,
                languageUid:this.props.langId,
                ogFields: ogFields ? ogFields : null
            }

            postAPI('product/product', data).then(res => {
                if (res.data.status === 1) {
                    console.log(res);
                    this.props.history.push('/products');
                }
                else if (res.data.status === 2) {
                    const { errors } = this.state;
                    errors.alreadyexist = 'Slug already exists'
                    this.setState({ isAlready: true, errors })
                } else if (res.data.status === 3) {
                    const { errors } = this.state;
                    errors.alreadyexist = 'Product already exists for this Brand'
                    this.setState({ isAlready: true, errors })
                } else {
                    alert('something went wrong')
                }
            }).catch(err => { console.log('Submit product error: ', err); this.setState({ submitting: false }) })
        }
    }
    constructor(props) {
        super();

        //  this.handleChange = this.handleChange.bind(this)
        this.modules = {
            toolbar: [
                [{ 'font': [] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image', 'video'],
                ['clean'],

            ]
        };

        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background',
            'link', 'image', 'video'
        ];
    }
    handleChange(html) {
        this.setState({ editorHtml: html });
    }

    handleOgDataChange = e => {
        console.log(e.target.getAttribute('name'), e.target.value)
        this.setState({
            ogFields:{
                ...this.state.ogFields,
                [e.target.getAttribute('name')] : e.target.value
                
            }
        })
    }

    render() {
        const { order, selectedBrand, brandList, brandLoading, selectedCategory, categories, categoryLoading, materialList,
            typeList, typeAndMaterialLoading, selectedMaterial, selectedType, tag, name, desc, slug, thumbnail, thumbnailUploading,
            images, imagesUploading, keywords, seoMetaKeyword, seoTitle, seoMetadesc, seoMetaFragment, seoMetaSummary,
            seoMetaBulletin, highlights, packaging, videoEmbedCode, isAlready, errors, islink, ogFields } = this.state;

        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Create New Products</h1>
                                    </div>
                                    <div className="col-auto">
                                        {/* <a href="/#" className="btn btn-primary lift">Create Products </a> */}
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2">Sort Order:</span>
                                            <input type="text" className="form-control width-auto"
                                                name="order" value={order} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        {isAlready && errors.alreadyexist.length > 0 ? <div className="alert alert-danger text-center" role="alert">
                            <div className="text-center">{errors.alreadyexist}</div>
                        </div> : ''}
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Brand</label>
                                    <Select
                                        value={selectedBrand}
                                        onChange={obj => this.handleChange(obj, 'selectedBrand')}
                                        options={brandList}
                                        isDisabled={brandLoading}
                                        isLoading={brandLoading}
                                        placeholder="Select Brand..."
                                    />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Category</label>
                                    <Select
                                        value={selectedCategory}
                                        onChange={obj => this.handleChange(obj, 'selectedCategory')}
                                        options={categories}
                                        isDisabled={categoryLoading}
                                        isLoading={categoryLoading}
                                        placeholder="Select Category..."
                                    />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Materials</label>
                                    <Select
                                        value={selectedMaterial}
                                        onChange={obj => this.handleChange(obj, 'selectedMaterial')}
                                        options={materialList}
                                        isDisabled={typeAndMaterialLoading}
                                        isLoading={typeAndMaterialLoading}
                                        placeholder="Select Materials..."
                                    />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Types</label>
                                    <Select
                                        value={selectedType}
                                        onChange={obj => this.handleChange(obj, 'selectedType')}
                                        options={typeList}
                                        isDisabled={typeAndMaterialLoading}
                                        isLoading={typeAndMaterialLoading}
                                        placeholder="Select Types..."
                                    />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" placeholder="Name.."
                                        name="name" value={name} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Tag Line</label>
                                    <input type="text" className="form-control" placeholder="Tagline.."
                                        name="tag" value={tag} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>

                            <div className="col-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    {/* <textarea className="form-control" placeholder="Description..."
                                        name="desc" value={desc} onChange={this.handleInputChange} /> */}
                                    <ReactQuill
                                        theme="snow"
                                        modules={this.modules}
                                        formats={this.formats}
                                        onChange={this.handleInputChange}
                                        value={this.state.desc}
                                    />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Slug</label>
                                    <input type="text" className="form-control" placeholder="slug..."
                                        name="slug" value={slug} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">

                                <div className="form-group">
                                    <label>Thumbnail Image</label>
                                    <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple">
                                        <UploadComponent imageUploading={thumbnailUploading} onDrop={files => this.onDrop(files, 'thumbnail')} />
                                    </div>
                                    {thumbnail !== '' ?
                                        <div className="box-im d-flex align-items-center mt-4">
                                            <div className="img-box-s mr-2">
                                                <img className="drop-image" src={`${storageUrl+ thumbnail}`} />
                                            </div>

                                            {/* <div className="show-img-info">
                                                <h4 className="mb-0  f-size">{this.state.files_body_content.name}</h4>
                                            </div> */}

                                        </div>
                                        : null
                                    }
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <label>Images</label>

                                <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple rem_border">
                                    <UploadComponent imageUploading={imagesUploading} onDrop={files => this.handleUploadImageAndLink(files,'image')}/>
                                    <div className="upload_link section text-center">
                                        <h2 className="color_text">or</h2>
                                        <button className="btn_cs" onClick={this.handleLinkInput}>upload link</button>
                                        {islink?<div className="link_box d-flex justify-content-center mb-4">
                                            <input type="text" className="link_text d-block" name="link" value={this.state.link} onChange={this.handleInputChange} placeholder="url.."/>
                                            <button type="button" className="btn_submit" onClick={e=>this.handleUploadImageAndLink(e,'link')}>add</button>
                                        </div>:''}
                                    </div>
                                </div>
                                {images.length ?
                                    <div className="box-im d-flex align-items-center mt-4">
                                        {
                                            images.map((item, idx) => (
                                                <div className="img-box-s mr-2 position-relative in_box_size" key={idx}>
                                                    {item.type===0?<img className="drop-image" src={`${storageUrl+ item.value}`} />:<img className="drop-image" src={`https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`} />}
                                                    <button type="button" className="btn_cr" onClick={e=>this.removeImage(e,item.value)}><span className="d-block">X</span></button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : null
                                }


                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Website Internal Search Keywords</label>

                                    <textarea className="form-control" name="keywords" value={keywords} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>SEO Meta Keywords</label>

                                    <textarea className="form-control" name="seoMetaKeyword" value={seoMetaKeyword} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>SEO Title</label>
                                    <input type="text" className="form-control" placeholder="Title.."
                                        name="seoTitle" value={seoTitle} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>SEO Meta Description</label>

                                    <textarea className="form-control" name="seoMetadesc" value={seoMetadesc} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>SEO Meta Fragment</label>

                                    <textarea className="form-control" name="seoMetaFragment" value={seoMetaFragment} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>SEO Meta Summary</label>

                                    <textarea className="form-control" name="seoMetaSummary" value={seoMetaSummary} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>SEO Meta Bulletin</label>

                                    <textarea className="form-control" name="seoMetaBulletin" value={seoMetaBulletin} onChange={this.handleInputChange} />
                                </div>
                                <hr className="my-5" />
                            </div>

                            {/* OG tags fields */}
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Title</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Title..."
                                        required="required"
                                        name="ogTitle"
                                        value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Description</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Description..."
                                        required="required"
                                        name="ogDescription"
                                        value={ogFields?.ogDescription ? ogFields?.ogDescription : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Image</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Image..."
                                        required="required"
                                        name="ogImage"
                                        value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                                <hr className="my-5"/>
                            </div>

                            
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Highlights</label>
                                    <textarea className="form-control" name="highlights" value={highlights} onChange={this.handleInputChange} />
                                    {/* <ReactQuill theme="snow" modules={this.modules}
                                        formats={this.formats} onChange={this.rteChange}
                                        value={this.state.comments || ''}
                                    /> */}

                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Packaging Info</label>
                                    <textarea className="form-control" name="packaging" value={packaging} onChange={this.handleInputChange} />
                                    {/* <ReactQuill theme="snow" modules={this.modules}
                                        formats={this.formats} onChange={this.rteChange}
                                        value={this.state.comments || ''}
                                    /> */}

                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Video Embed Code</label>
                                    <textarea className="form-control" name="videoEmbedCode" value={videoEmbedCode} onChange={this.handleInputChange} />
                                    {/* <ReactQuill theme="snow" modules={this.modules}
                                        formats={this.formats} onChange={this.rteChange}
                                        value={this.state.comments || ''}
                                    /> */}

                                </div>
                                <hr className="my-5" />
                            </div>


                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={() => this.props.history.push('/products')}>Cancel</button>
                                    <button className="btn btn-primary" disabled={this.state.submitting} onClick={this.handleSave}>Create Product</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}