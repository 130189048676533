import React, { Component } from 'react';

import { getAPI, postAPI, putAPI } from '../../utils/api';
import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/markdown/markdown';

export default class WebUrls extends Component {
  state = {
    isLoading: true,
    submitting: false,
    topBanner: [{ image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null }],

    apiList: ['topBanner'],
    selectedBanner: { title: 'code' }
  }

  componentDidMount() {
    console.log(this.props.language)
    const errors = []

    getAPI(`template/getTemplate?menuId=22&type=topBanner&lang=${this.props.language}`)
      .then(res => {
        const { data, status } = res.data;
        console.log(data, status)
        data && (status === 1
          ? this.setState({ topBanner: data[0], selectedBanner: data[0], isLoading: false })
          : errors.push('topBanner'))
      }).catch(err => { console.log(err) });



  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.setState({ isLoading: true });
      this.componentDidMount();
    }
  }

  onCodeChange = (code) => {
    const { selectedBanner } = this.state;
    const newData = { ...selectedBanner, title: code }
    this.setState({ selectedBanner: newData });
  }

  handleSave = (e, name, menuId) => {
    const { language } = this.props;
    const { selectedBanner } = this.state;
    const { image } = selectedBanner;

    if (name === 'topBanner') {
      this.setState({ submitting: true });
      let newObj = {
        ...selectedBanner,
        title: { [language]: selectedBanner.title },
        sub: { [language]: '' },
      }

      if (newObj.id) {
        const data = {
          "type": name,
          "menuId": menuId,
          "templateData": newObj,
          "lang":this.props.language
        }
        console.log(data)
        putAPI('template/editTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({ submitting: false });
        }).catch(error => {
          console.log(error)
        })
      } else {
        const data = {
          "templateType": name,
          "menuId": menuId,
          "templateData": newObj,
          "lang":this.props.language
        }
        postAPI('template/addTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({ submitting: false });
        }).catch(error => {
          console.log(error)
        })
      }

    } else {
      alert('Image also needed.')
    }
  }

  render() {
    const { selectedBanner, submitting, isLoading } = this.state;
    const { title } = selectedBanner;
    const options = {
      lineNumbers: true,
    };
    // console.log(selectedBanner)

    return (
      isLoading ? '' :
        <React.Fragment>
          <div className="main-section mt-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="d-flex">
                    <h1 className="header-title">Web URL's</h1>
                  </div>
                </div>
                <div className="spac w-100" />

                <div className="col-sm-12">
                  <div className="form-group" style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <CodeMirror value={title} onChange={this.onCodeChange} options={options} />
                  </div>
                  <div className='form-group'>
                    <button type="button" className="btn btn-primary mr-auto" disabled={submitting}
                      onClick={(e) => this.handleSave(e, 'topBanner', 22)}
                    >
                      {submitting ? 'Saving...' : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    )
  }
}