import React, {Component} from 'react';
import BannerLogin from '../../Assets/img/covers/signup_banner.jpg';
import {postPublicAPI} from '../../utils/api';

export default class Login extends Component {
  state = {
    email: '',
    password: '',
    errmsg:'',
    error:false,
    errors:{
      email:'',
      password:''
    },
    isSubmitting:false,
    isValid:false,
    showPwd:false
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
    let errors=this.state.errors;
        let {name,value}=e.target;
         
      switch (name) {
      case 'email': 
        errors.email = 
          value.length <=0
            ? 'Field is Required!'
            : '';
        break;
      case 'password': 
        errors.password = 
            value.length<=0
            ? 'Field is Required!'
            : '';
        break;
      default:
        break;
    }
  }

  handleLogin = (e) => {
    e.preventDefault();
    const email=this.state.email;
    const password=this.state.password;
    const errors=this.state.errors;
    if(email.length===0||password.length===0){
      console.log('In if')
       if(email.length===0&&password.length===0){
                errors.email="Field is Required!";
                errors.password="Field is Required!";
                this.setState({errors,isValid:true});
            }else if(email.length===0){
                errors.email='Field is Required!'
                this.setState({errors,isValid:true});
            }else{
                errors.password='Field is Required!'
                this.setState({errors,isValid:true});
            }
    }else{
      this.setState({isSubmitting:true})

      const data={
        email:this.state.email,
        password:this.state.password
      }
    postPublicAPI('users/login', data)
      .then(res => {
        const {status}=res.data;
        if(status===1){
          this.setState({error:false,isSubmitting:false})
        localStorage.setItem("userToken", res.data.token);
        this.props.reload();
        this.props.history.push('/');
        }else{
          this.setState({error:true,errmsg:res.data.message,isSubmitting:false})
          this.setErrorTimeout();
        }
      })
      .catch(err => {
        console.log(err)
        alert('Something went wrong. Please try again.')
      })
  }
  }

  setErrorTimeout=()=>{
    setTimeout(()=>this.setState({error:false}), 3000);

  };
  handlePasswordType=()=>{
    this.setState({showPwd:!this.state.showPwd});
  }
  render(){
    const {email, password,isSubmitting,errors,isValid,showPwd} = this.state;
    return(
            <React.Fragment>
              
              <div className="main-section">
                <div className="container-fluid">
                  <div className="row align-items-center justify-content-center " >

                    {/* Left Section start */}
                    <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
                      {/* <h1 className="display-4 text-center mb-3">Sign in</h1>
                      <p className="text-muted text-center mb-5">
                        Free access to our dashboard.
                      </p> */}
                      {this.state.error?<div className="alert alert-danger fade show error-message" role="alert">
                                          <div className="text-center">{this.state.errmsg}</div>
                                        </div>:''}
                      <div className="logo-section text-center mb-5">
                        <img src={require('../../Assets/img/kintlogo/knitpro-logo.svg') } alt="..." />
                      </div>
                      <form >
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            type="email"
                            className="form-control focus-out"
                            placeholder="name@address.com"
                            name="email"
                            value={email}
                            onChange={this.onChange}
                          />
                          {isValid && errors.email.length > 0?
                                <span  className="error-field-error">{errors.email}</span>:''}
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col">
                              <label>Password</label>
                            </div>
                            {/* <div className="col-auto">
                              <a
                                href="password-reset-cover.html"
                                className="form-text small text-muted"
                              >
                                Forgot password?
                              </a>
                            </div> */}
                          </div>
                          <div className="input-group input-group-merge">
                            <input
                              type={`${showPwd ?`text`:'password'}`}
                              className="form-control focus-out form-control"
                              placeholder="Enter your password"
                              name="password"
                              value={password}
                              onChange={this.onChange}
                            />
                           
                            <div className="input-group-append show-password">
                              <span className="" onClick={this.handlePasswordType}>
                               <i className={`fe ${showPwd?`fe-eye-off` :`fe-eye` }`} />
                              </span>
                            </div>
                            
                          </div>
                           {isValid && errors.password.length > 0?
                                <span  className="error-field-error">{errors.password}</span>:''}
                        </div>
                        <button className="btn btn-lg btn-block btn-primary mb-3" type="submit" disabled={isSubmitting} onClick={this.handleLogin}>
                          Sign in
                        </button>
                      </form>
                    </div>
                    {/* Left Section End */}

                    {/* Right Section start */}
                    <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
                      <div
                        className="bg-cover vh-100 mt-n1 mr-n3"
                        style={{ background: `url(${BannerLogin})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover',backgroundPosition: 'center'}}
                      />
                    </div>
                    {/* Right Section End */}
                  </div>
                </div>
              </div>

            </React.Fragment>
          )
        }
      }