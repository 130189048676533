import React, { Component } from 'react';

import { getAPI, postAPI, putAPI } from '../../utils/api';
import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/markdown/markdown';

export default class TheMindfulCollection extends Component {
   state = {
      isLoading: true,
      submitting: false,
      topBanner: [{ image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null }],

      apiList: ['topBanner'],
      selectedBanner: { title: 'code' },
      ogFields: {}
   }

   componentDidMount() {
      console.log(this.props.language, "LLLL")
      const errors = []

      getAPI(`template/getTemplate?menuId=23&type=topBanner&lang=${this.props.language}`)
         .then(res => {
            const { data, status } = res.data;
            console.log(data, status, 'ddddddddd')
            if (data && status) {
               this.setState({ topBanner: data[0], selectedBanner: data[0], ogFields: (data[0].ogFields)?.[this.props.language], isLoading: false })
            }
            // data && (status === 1
            //    ? 
            //    : errors.push('topBanner'))
         }).catch(err => { console.log(err) });



   }

   componentDidUpdate(prevProps) {
      if (this.props.language !== prevProps.language) {
         this.setState({ isLoading: true });
         this.componentDidMount();
      }
   }

   onCodeChange = (code) => {
      const { selectedBanner } = this.state;
      const newData = { ...selectedBanner, title: code }
      this.setState({ selectedBanner: newData });
   }

   handleSave = (e, name, menuId) => {
      const { language } = this.props;
      const { selectedBanner, ogFields } = this.state;
      const { image } = selectedBanner;

      if (name === 'topBanner') {
         this.setState({ submitting: true });
         let newObj = {
            ...selectedBanner,
            title: { [language]: selectedBanner.title },
            sub: { [language]: '' },
         }

         if (newObj.id) {
            const data = {
               "type": name,
               "menuId": menuId,
               "templateData": newObj,
               "ogFields": ogFields,
               "lang": this.props.language
            }
            console.log(data)
            putAPI('template/editTemplate', data).then(res => {
               console.log(res);
               this.componentDidMount();
               this.setState({ submitting: false });
            }).catch(error => {
               console.log(error)
            })
         } else {
            const data = {
               "templateType": name,
               "menuId": menuId,
               "templateData": newObj,
               "ogFields": ogFields,
               "lang": this.props.language
            }
            postAPI('template/addTemplate', data).then(res => {
               console.log(res);
               this.componentDidMount();
               this.setState({ submitting: false });
            }).catch(error => {
               console.log(error)
            })
         }

      } else {
         alert('Image also needed.')
      }
   }

   handleOgDataChange = e => {
      console.log(e.target.getAttribute('name'), e.target.value)
      this.setState({
         ogFields: {
            ...this.state.ogFields,
            [e.target.getAttribute('name')]: e.target.value

         }
      })
   }

   render() {
      const { selectedBanner, submitting, isLoading, ogFields } = this.state;
      const { title } = selectedBanner;
      const options = {
         lineNumbers: true,
      };
      // console.log(selectedBanner)

      console.log(this.state, "<><><><")

      return (
         isLoading ? '' :
            <React.Fragment>
               <div className="main-section mt-5">
                  <div className="container-fluid">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="d-flex">
                              <h1 className="header-title">The Mindful Collection</h1>
                           </div>
                        </div>
                        <div className="spac w-100" />

                        <div className="col-sm-12">
                           <div className="form-group" style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
                              <CodeMirror value={title} onChange={this.onCodeChange} options={options} />
                           </div>
                           {/* OG tags fields */}
                           <div className="col-12">
                              <div className="spac w-100" />
                              <div className="form-group">
                                 <label>OG Title</label>
                                 <input type="text"
                                    className="form-control"
                                    placeholder="OG Title..."
                                    required="required"
                                    name="ogTitle"
                                    value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                                    onChange={e => this.handleOgDataChange(e)}
                                 />
                              </div>
                           </div>
                           <div className="col-12">
                              <div className="form-group">
                                 <label>OG Description</label>
                                 <input type="text"
                                    className="form-control"
                                    placeholder="OG Description..."
                                    required="required"
                                    name="ogDescription"
                                    value={ogFields?.ogDescription ? ogFields?.ogDescription : ""}
                                    onChange={e => this.handleOgDataChange(e)}
                                 />
                              </div>
                           </div>
                           <div className="col-12">
                              <div className="form-group">
                                 <label>OG Image</label>
                                 <input type="text"
                                    className="form-control"
                                    placeholder="OG Image..."
                                    required="required"
                                    name="ogImage"
                                    value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                                    onChange={e => this.handleOgDataChange(e)}
                                 />
                              </div>
                              <hr className="my-5" />
                           </div>

                           <div className='form-group'>
                              <button type="button" className="btn btn-primary mr-auto" disabled={submitting}
                                 onClick={(e) => this.handleSave(e, 'topBanner', 23)}
                              >
                                 {submitting ? 'Saving...' : 'Save'}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </React.Fragment>
      )
   }
}