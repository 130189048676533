import axios from 'axios';
import React, {Component} from 'react';
import { getAPI, postAPI } from '../utils/api';

export default class Header extends Component {
    state = {
        allLanguages: [],
        langName:'Language',
        deploying: false
    }

    componentDidMount(){
        getAPI('language/language?lang=en')
            .then(res => {
                this.setState({allLanguages: res.data.data})
            }).catch(err => console.log('lang error: ', err.response))
    }
    onlangSelect=(val)=>{
        this.setState({langName:val})
    }

    deploy = () => {
        this.setState({deploying: true}, async () => {
            postAPI("deploy/knitpro").then(res => {
                if(res){
                    this.setState({deploying: false})
                }
            })
        })
    }

    render(){
        const {setLanguage} = this.props;

        return(
            <div className="col-12 col-xl-12">
                <div className="card text-right p-3">
                <div className="dropdown">
                    <button className="btn btn-outline-primary mx-3" onClick={this.deploy} >
                        { this.state.deploying ? "...Deploying" : "Deploy Knitpro" }
                    </button>
                    <button className="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButtonAlias" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.state.langName}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonAlias">
                        {this.state.allLanguages.map((obj, idx) => (
                            <li key={idx}>
                                <a className="dropdown-item" href="" onClick={e => {
                                    setLanguage(e, obj.shortName,obj.uid);
                                    {this.onlangSelect(obj.name)}
                                    } }>{obj.name}</a>
                            </li>
                        ))}
                    </ul>
                    </div>
                </div>
            </div>
        )
    }
}